import * as React from 'react';

import Layout from '../components/layout';
import Signup from '../components/common/signup';
import PricingPlans from '../components/pricing/pricingPlans';
import PlanFeatures from '../components/pricing/planFeatures';
import Faq from '../components/pricing/faq';
import SEO from '../components/seo';



const IndexPage = () => (
  <Layout>
    <SEO title='Pricing' description={['View pricing for web and mobile session replay']} />
    <PricingPlans />
    <PlanFeatures />
    <Faq />
    <Signup />
  </Layout>
)

export default IndexPage;

import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SanityHeroComponentBlock from '../blocks/SanityHeroComponent'

export default () => {
  const signupData = useStaticQuery(graphql`
    query Signup {
      sanityHeroComponent(internalName: {eq: "sign up component"}) {
        ...SanityHeroComponent
      }
    }
  `)
  return <SanityHeroComponentBlock {...signupData?.sanityHeroComponent}/>
};

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SanityPortableText from '../shared/SanityPortableText'

export default () => {
  const frqData = useStaticQuery(graphql`
    query Faq {
      sanityCardsData(internalName: {eq: "Frequently Asked Questions cards"}) {
        ...SanityCardsData
      }
    }
  `);
  const { cardsTitle, cardData, backgroundColor } = frqData?.sanityCardsData || {}
  return (<div className='faq' style={{ backgroundColor: backgroundColor?.hex }}>
    <div className='container'>
      <div className='row'>
        <div className='col-md-12'>
          {cardsTitle.map(({ _id: id, ...texts }) => (
            <SanityPortableText _id={id} key={id} {...texts} />
          ))}
        </div>
      </div>
      <div className='row'>
        {cardData.map(({ text, cardTitle, id }) => (
          <div className='col-md-6' key={id}>
            <div className='question_box'>
              <SanityPortableText {...cardTitle} />
              {text.map(({ _id: textId, ...texts }) => <SanityPortableText key={textId} _id={textId} {...texts} />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
  )
};

import React from 'react';

import iconCheck from '../../assets/svgs/icon-check-purple.svg';
import featuresCR from "../../assets/svgs/icon-features-conditional-recording.svg";
import featuresCapture from "../../assets/svgs/icon-features-auto-capture.svg";
import featuresHosting from "../../assets/svgs/icon-features-saas-self-hosted.svg";
import featuresPricing from "../../assets/svgs/icon-features-billing-pricing.svg";
import featuresGalileo from "../../assets/svgs/icon-features-galileo.svg";
import featuresGalileoInverted from "../../assets/svgs/icon-features-galileo-inverted.svg";
import featuresIssues from "../../assets/svgs/icon-features-issue-management.svg";
import featuresAnalytics from "../../assets/svgs/icon-features-product-ux-analytics.svg";
import featuresReplay from "../../assets/svgs/icon-features-session-replay.svg";
import featuresPlatform from "../../assets/svgs/icon-features-platform.svg";
import featuresUsage from "../../assets/svgs/icon-features-usage.svg";
import featuresService from "../../assets/svgs/icon-features-service.svg";

type FeatureSection = {
  name: string,
  icon: string,
  entries: FeatureEntry[],
};

type PlanType = "free" | "team" | "pro" | "enterprise";

type PlanDetail = {
  plan: PlanType,
  detail: string;
};

type FeatureEntry = {
  name: string,
  plans: (PlanType | PlanDetail)[],
  tooltip?: string,
};

const sections: FeatureSection[] = [
  {
    name: "Session replay", icon: featuresReplay, entries: [
      {
        name: "Web replays",
        plans: ["free", "team", "pro", "enterprise"],
      },
      {
        name: "Native mobile replays",
        plans: ["free", "team", "pro", "enterprise"]
      },
      {
        name: "Filters and search",
        plans: ["free", "team", "pro", "enterprise"],
        tooltip: "Filter and find sessions using any combination of over 50 filters"
      },
      {
        name: "iFrame capture",
        plans: ["free", "team", "pro", "enterprise"],
        tooltip: "View embedded iFrames for owned properties within session replays"
      },
      {
        name: "Go Live",
        plans: ["free", "team", "pro", "enterprise"],
        tooltip: "Follow along with active users in real time"
      },
      {
        name: "Console logs, warnings, errors & exceptions", plans: ["free", "team", "pro", "enterprise"]
      },
      {
        name: "Stack traces & source maps",
        plans: ["free", "team", "pro", "enterprise"],
        tooltip: "Pinpoint the line of broken code and its source"
      },
      {
        name: "Network requests, headers & bodies", plans: ["free", "team", "pro", "enterprise"]
      },
      {
        name: "Blocklisting & allowlisting",
        plans: ["free", "team", "pro", "enterprise"],
        tooltip: "Securely block or allow specific elements and fields to protect user privacy"
      },
      {
        name: "Inspect tool",
        plans: ["pro", "enterprise"],
        tooltip: "Click on any element in a session replay to get instant analytics"
      },
      {
        name: "Performance data",
        plans: ["pro", "enterprise"],
        tooltip: "Measure end user performance such as network speed, load performance, CPU/memory usage, and more"
      },
    ]
  },
  {
    name: "Product & UX Analytics", icon: featuresAnalytics, entries: [
      {
        name: "Dashboards & templates",
        plans: ["pro", "enterprise"],
        tooltip: "Get started quickly with pre-built templates, or build a custom dashboard to track the metrics that are most important to you",
      },
      {
        name: "Tables & timeseries",
        plans: ["pro", "enterprise"],
        tooltip: "Understand how user behavior is trending and changes over time"
      },
      {
        name: "Conversion funnels",
        plans: ["pro", "enterprise"],
        tooltip: "Track conversion between multiple steps or paths within your application"
      },
      {
        name: "Funnel insights",
        plans: ["pro", "enterprise"],
        tooltip: "Identify the highest-impact issues affecting conversion"
      },
      {
        name: "Path analysis",
        plans: ["pro", "enterprise"],
        tooltip: "Understand how users navigate to or from a specific event in your application"
      },
      {
        name: "Heatmaps, scrollmaps & clickmaps",
        plans: ["pro", "enterprise"],
        tooltip: "Visualize where users hover, where they scroll, and what clicks lead to conversion"
      },
      {
        name: "Cohort analysis",
        plans: ["pro", "enterprise"],
        tooltip: "Build user cohorts based on behavior, traits, or metadata"
      },
      {
        name: "Retention charts",
        plans: ["pro", "enterprise"],
        tooltip: "Visualize return user behavior over time"
      },
      {
        name: "Alerting",
        plans: ["pro", "enterprise"],
        tooltip: "Get notified via Slack, email, PagerDuty or webhook when metrics go above or below desired thresholds"
      },
      {
        name: "Definitions",
        plans: ["pro", "enterprise"],
        tooltip: "Label pages, technical selectors, and elements in business terms. LogRocket retroactively captures Definition data",
      },
      {
        name: "Streaming Data Export",
        plans: [{ plan: "pro", detail: "Add on" }, "enterprise"],
        tooltip: "Create a direct connection to your data warehouse and have data arrive in a ready-to-analyze format"
      },
    ]
  },
  {
    name: "Issue Management", icon: featuresIssues, entries: [
      {
        name: "Issue triaging",
        plans: ["free", "team", "pro", "enterprise"],
        tooltip: "Prioritize the high-impact issues and set rules to get alerted when similar issues occur. Visualize app health over time with our issue summary histogram"
      },
      {
        name: "Issue frequency analysis",
        plans: ["free", "team", "pro", "enterprise"],
        tooltip: "How many sessions and users an issue affects, how often it’s occurring, and which types of users it’s happening to"
      },
      {
        name: "Stack traces & source maps",
        plans: ["free", "team", "pro", "enterprise"],
        tooltip: "For both web and native mobile"
      },
      {
        name: "JavaScript errors", plans: ["free", "team", "pro", "enterprise"],
      },
      {
        name: "Mobile exceptions", plans: ["free", "team", "pro", "enterprise"],
      },
      {
        name: "Network errors", plans: ["pro", "enterprise"],
      },
      {
        name: "Error states",
        plans: ["pro", "enterprise"],
        tooltip: "Locate and resolve error messages and high-visibility instances where users are told something has gone wrong"
      },
      {
        name: "Rage clicks",
        plans: ["pro", "enterprise"],
        tooltip: "Find where users are clicking in frustration"
      },
      {
        name: "Dead clicks",
        plans: ["pro", "enterprise"],
        tooltip: "Find where clicks are failing to lead to changes in the DOM"
      },
      {
        name: "Severity scores",
        plans: ["pro", "enterprise"],
        tooltip: "Galileo machine learning surfaces the issues with the greatest impact on your app"
      },
      {
        name: "Issue descriptions",
        plans: ["pro", "enterprise"],
        tooltip: "Get issues described in terms anyone can understand at a glance"
      },
      {
        name: "Alerting",
        plans: ["pro", "enterprise"],
        tooltip: "Get notified via Slack, email, PagerDuty or webhook when severe issues occur"
      },
      {
        name: "Issues Digest",
        plans: ["pro", "enterprise"],
        tooltip: "Configurable briefings of the issues that are most important to you, delivered via Slack, Teams, email, or webhook"
      },
    ]
  },
  {
    name: "Galileo AI", icon: featuresGalileo, entries: [
      {
        name: "Severity scores",
        plans: ["pro", "enterprise"],
        tooltip: "Machine learning surfaces the issues with the greatest impact on your app"
      },
      {
        name: "Issue descriptions",
        plans: ["pro", "enterprise"],
        tooltip: "Get issues described in terms anyone can understand at a glance"
      },
      {
        name: "Recommended issues",
        plans: ["pro", "enterprise"],
      },
      {
        name: "Issues Digest",
        plans: ["pro", "enterprise"],
        tooltip: "Configurable briefings of the issues that are most important to you, delivered via Slack, Teams, email, or webhook"
      },
    ]
  },
  {
    name: "Platform & Security", icon: featuresPlatform, entries: [
      {
        name: "Data erasure",
        plans: ["free", "team", "pro", "enterprise"],
        tooltip: "Erase customer data within 24 hours"
      },
      {
        name: "Integrations",
        plans: ["free", "team", "pro", "enterprise"],
        tooltip: "Connect LogRocket to your existing analytics, issue resolution, and support flows"
      },
      {
        name: "Autocapture",
        plans: ["free", "team", "pro", "enterprise"],
        tooltip: "LogRocket captures and tracks every event, page, and action without the need for additional instrumentation"
      },
      {
        name: "GDPR & CCPA compliance",
        plans: ["free", "team", "pro", "enterprise"],
        tooltip: "Easily remove sessions at users’ request to comply with GDPR and CCPA requirements"
      },
      {
        name: "Blocklisting & allowlisting",
        plans: ["free", "team", "pro", "enterprise"],
        tooltip: "Securely block or allow specific elements and fields to protect user privacy"
      },
      {
        name: "PII audit",
        plans: ["free", "team", "pro", "enterprise"],
        tooltip: "Find sessions where you redacted information from input fields, but not corresponding network requests"
      },
      {
        name: "SOC II compliance", plans: ["free", "team", "pro", "enterprise"]
      },
      {
        name: "Single sign on", plans: ["pro", "enterprise"]
      },
      {
        name: "Role-based access controls",
        plans: ["pro", "enterprise"],
        tooltip: "Define custom roles within LogRocket to determine who has access to different projects and information"
      },
      {
        name: "Audit logs",
        plans: ["pro", "enterprise"],
        tooltip: "A detailed trail of account activity within LogRocket"
      },
      {
        name: "Feedback", plans: [{ plan: "pro", detail: "Add on" }, "enterprise"]
      },
      {
        name: "Conditional Recording",
        plans: [{ plan: "pro", detail: "Add on" }, { plan: "enterprise", detail: "Add on" }],
        tooltip: "Create rules to capture only the sessions you need"
      },
      {
        name: "Self-hosted",
        plans: [{ plan: "enterprise", detail: "Available" }],
        tooltip: "Deploy LogRocket on your own cloud instance"
      },
    ]
  },
  {
    name: "Usage", icon: featuresUsage, entries: [
      {
        name: "Seats", plans: [{ plan: "free", detail: "3" }, { plan: "team", detail: "5-10" }, { plan: "pro", detail: "Custom" }, { plan: "enterprise", detail: "Unlimited" }]
      },
      {
        name: "Sessions", plans: [{ plan: "free", detail: "1K/month" }, { plan: "team", detail: "10K-50K/mo." }, { plan: "pro", detail: "Any volume" }, { plan: "enterprise", detail: "Any volume" }]
      },
      {
        name: "Data retention", plans: [{ plan: "free", detail: "1 mo." }, { plan: "team", detail: "Starts at 1 mo." }, { plan: "pro", detail: "Custom" }, { plan: "enterprise", detail: "Custom" }]
      },
    ]
  },
  {
    name: "Service", icon: featuresService, entries: [
      {
        name: "Email support", plans: ["free", "team", "pro", "enterprise"]
      },
      {
        name: "Live chat support", plans: ["team", "pro", "enterprise"]
      },
      {
        name: "Customer success manager",
        plans: ["pro", "enterprise"],
        tooltip: "Dedicated customer success manager for plans greater than $12K annually. Access to customer success for all other Pro plans"
      },
      {
        name: "Basic legal/security", plans: ["pro", "enterprise"]
      },
      {
        name: "Custom legal/security/BAA", plans: ["pro", "enterprise"]
      },
      {
        name: "Uptime SLA", plans: ["enterprise"]
      },
    ]
  },
  {
    name: "Billing & Pricing", icon: featuresPricing, entries: [
      {
        name: "Payment types", plans: [{ plan: "free", detail: "N/A" }, { plan: "team", detail: "Credit card" }, { plan: "pro", detail: "Credit card or invoice" }, { plan: "enterprise", detail: "Invoice" }]
      },
      {
        name: "Term", plans: [{ plan: "free", detail: "Free Forever" }, { plan: "team", detail: "Monthly" }, { plan: "pro", detail: "Annual, billed monthly or annually" }, { plan: "enterprise", detail: "Annual" }]
      },
    ]
  },
];

/**
 * Returns the text to display if `feature` is included in `plan`
 *
 * Returns null if `feature` is not a part of `plan`
 */
const FeatureInPlanCell = ({ feature, plan }: { feature: FeatureEntry, plan: PlanType; }) => {
  const availability = feature.plans.find((p) => typeof p === "string" ? p === plan : p.plan === plan);

  if (!availability) {
    return null;
  }

  if (typeof availability === "string") {
    return <img src={iconCheck} className='img-fluid d-block mx-auto' alt='Check' />;
  }

  return <span>{availability.detail}</span>;
};

export default () => (
  <div className='plan_features'>
    <div className='container'>
      <div className='row'>
        <div className="col-md-11">
          <h2>Core Features</h2>
          <div className="core-features-container">
            <div className="core-feature">
              <img src={featuresGalileoInverted} alt="Galileo" />
              <span className="core-feature-title">Galileo</span>
              <span>
                LogRocket’s AI watches every session to surface user struggle & behavioral patterns.
              </span>
            </div>

            <div className="core-feature">
              <img src={featuresCR} alt="Conditional Recording" />
              <span className="core-feature-title">Conditional Recording</span>
              <span>
                Stop making tradeoffs between capturing more sessions and increasing costs.
              </span>
            </div>

            <div className="core-feature">
              <img src={featuresCapture} alt="Auto Capture" />
              <span className="core-feature-title">Auto-capture</span>
              <span>
                No tagging needed - capture every data point with one line of code.
              </span>
            </div>

            <div className="core-feature">
              <img src={featuresHosting} alt="Saas & Self-Hosting" />
              <span className="core-feature-title">SaaS & Self-Hosted</span>
              <span>
                Deploy LogRocket in the way that works best for your organization.
              </span>
            </div>
          </div>
        </div>

        <div className='col-md-11'>
          <h2>Compare Plans and Features</h2>
          {sections.map((section: FeatureSection) => (
            <>
              <div className='swipe'>← SWIPE THE TABLE TO SEE MORE →</div>
              <div className="table-responsive long_table">
                <table className='table'>
                  <thead>
                    <tr>
                      <th><div><img src={section.icon} alt={`Icon for ${section.name}`} />{section.name}</div></th>
                      <th>FREE</th>
                      <th>TEAM</th>
                      <th>PROFESSIONAL</th>
                      <th>ENTERPRISE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {section.entries.map((entry: FeatureEntry) => (
                      <tr key={entry.name}>
                        <td>{entry.name}
                          {entry.tooltip && (
                            <div className='tooltip_mark _tooltip'>
                              ?<div className='_tooltiptext'>{entry.tooltip}</div>
                            </div>
                          )}</td>
                        <td><FeatureInPlanCell feature={entry} plan="free" /></td>
                        <td><FeatureInPlanCell feature={entry} plan="team" /></td>
                        <td><FeatureInPlanCell feature={entry} plan="pro" /></td>
                        <td><FeatureInPlanCell feature={entry} plan="enterprise" /></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ))}
        </div>
        <div className='col-md-11 d-none'>
          <div className='choose-one-cards'>
            <div className='text_center my-5'>
              <h4>Getting Started Is Easy</h4>
              <p>
                Choose a plan and be up and running in minutes.
              </p>
            </div>
            <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-5 g-md-2 g-lg-3">
              <div className="col my-3">
                <div className="p-3 border bg-white free g-3">
                  <h5 className='mb-3 mb-lg-5 mt-3'>FREE</h5>
                  <button className='btn btn-primary signup mb-2' type='button'>
                    Create Account
                  </button></div>
              </div>
              <div className="col  my-3">
                <div className="p-3 border bg-white team">
                  <h5 className='mb-3 mb-lg-5 mt-3'>TEAM</h5>
                  <button className='btn btn-primary signup mb-2' type='button'>
                    14 Days Free
                  </button></div>
              </div>
              <div className="col  my-3">
                <div className="p-3 border bg-white professional">
                  <h5 className='mb-3 mb-lg-5 mt-3'>PROFESSIONAL</h5>
                  <a
                    href='https://logrocket.com/contact/'
                    className='btn btn-primary mb-2'
                  >
                    14 Days Free
                  </a></div>
              </div>
              <div className="col  my-3">
                <div className="p-3 border bg-white enterprise">
                  <h5 className='mb-3 mb-lg-5 mt-3'>ENTERPRISE</h5>
                  <a
                    href='https://logrocket.com/contact/'
                    className='btn btn-primary mb-2'
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
